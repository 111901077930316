<template>
  <div class="preview dark" v-loading="loading" element-loading-text="加载中..." :element-loading-spinner="svg"
    element-loading-svg-view-box="-10, -10, 50, 50" element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="preview-header">
      <div class="breadcrumb-head">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item
            v-for="(n, i) in project.routes"
            :key="i">
            <span v-if="n.disable" style="cursor: pointer; color: #1eaaff" @click="jumpSpace(n)">
            {{n.name}}</span>
            <span v-else style="color: #b3bfc7">
            {{n.name}}</span>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="preview-body2">
      <el-alert title="注意： 过期链接14天后将自动清除" type="info" style="width: 30%;margin-left: 30%;" />
      <el-scrollbar>
        <div class="frame common-frame">
          <query style="margin-top:20px;">
            <template #left>
              <query-item query-name="创建人">
                <template #item>
                  <el-select v-model="query.createBy" placeholder="选择用户" @change="filterByUser" style="width:100%">
                    <el-option v-for="item in usersOptions" :key="item.id" :label="item.nickname" :value="item.id">
                    </el-option>
                  </el-select>
                </template>
              </query-item>
            </template>
          </query>
          <el-table :data="tableData" style="width: 100%">
            <el-table-column min-width="5%" />
            <el-table-column label="链接地址" prop="name" min-width="30%"></el-table-column>
            <el-table-column label="有效期" prop="effectName" min-width="10%" />
            <el-table-column label="支持克隆" prop="typeName" min-width="15%" />
            <el-table-column label="来源模块" prop="moduleName" min-width="15%" />
            <el-table-column label="创建人" prop="userName" min-width="15%" />
            <el-table-column label="创建时间" prop="createDate" min-width="25%" />
            <el-table-column label="操作" min-width="10%">
              <template #default="scope">
                <el-button link size="small" @click.prevent="editShot(scope.$index, tableData)" v-if="editAuth">
                  编辑
                </el-button>
                <el-button link size="small" @click.prevent="shareDel(scope.$index, tableData)" v-if="delAuth">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="line pd-10">
            <page :total="conf.total" v-if="tableData.length" @onChange="onChange"></page>
          </div>
        </div>
      </el-scrollbar>
    </div>
    <window :title="share.title" :shadow="true" class="dark" @windowHide="shareHide()" windowId="version-share"
      v-show="share.isShow">
      <template #body>
        <div class="line pd-20">
          <div class="insert-h">
            <div class="attr">有效时间</div>
            <div class="val">
              <el-radio-group v-model="share.info.effect" @change="changeExpire">
                <el-radio label="hour1">1小时</el-radio>
                <el-radio label="day1">1天</el-radio>
                <el-radio label="day3">3天</el-radio>
                <el-radio label="day7">7天</el-radio>
                <el-radio label="day14">14天</el-radio>
                <el-radio label="forever">永久</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="insert-h">
            <div class="attr">授权码</div>
            <div class="val" style="width:25%;">
              <el-input v-model="share.info.code" maxlength="6" placeholder="请输入授权码" />
            </div>
          </div>
          <div class="insert-h">
            <div class="attr">克隆</div>
            <div class="val">
              <el-switch v-model="share.info.type" @change="switchClone" inline-prompt active-text="是" inactive-text="否"
                :active-value="1" :inactive-value="0" style="margin:4px 0" />
            </div>
          </div>
          <div class="insert-h" v-if="share.info.type == 1">
            <div class="attr">克隆码</div>
            <div class="val" style="width:25%;">
              <el-input v-model="share.info.cloneCode" maxlength="6" placeholder="请输入克隆码" />
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="line pd-20 align-right">
          <el-button size="small" @click="shareHide()">取消</el-button>
          <el-button size="small" type="primary" @click="saveShareCode()">确定</el-button>
        </div>
      </template>
    </window>
  </div>
</template>

<script>
import Window from 'components/common/forms/Window';
import Page from 'components/common/forms/Page';
import Query from "components/common/forms/Query";
import QueryItem from "components/common/forms/QueryItem";
import { prjBtnStatus, getSpaceOrPrjDetails, jumpRouteSetup } from 'common/authBtn';
import _ from 'underscore';
import { useStore } from 'vuex';
import { useRouter } from "vue-router"
import { ref, onMounted, getCurrentInstance, provide, nextTick } from 'vue';

export default {
  name: 'Share',
  components: {
    Window,
    Page,
    Query,
    QueryItem,
  },
  emits: ['changeNavi'],
  setup (props, {emit}) {
    const store = useStore();
    const router = useRouter();
    let loading = ref(true), project = ref({}), usersOptions = ref([]), tableData = ref([]), share = ref({
      isShow: false,
      isEdit: false,
      title: "",
      info: {
        name: '',
        description: '',
      }
    }), addAuth = ref(false), editAuth = ref(false), delAuth = ref(false),
      conf = ref({
        pageSize: 10,
        pageNum: 1,
        total: 10
      }), query = ref({
        createBy: '-1',
      }), record = ref('');

    const $api = getCurrentInstance().appContext.config.globalProperties.$api;
    const $confirm = getCurrentInstance().appContext.config.globalProperties.$confirm;
    const $message = getCurrentInstance().appContext.config.globalProperties.$message;

    const getDetail = () => {
      getSpaceOrPrjDetails(store).then((res) => {
        project.value = res;
        prjBtnStatus(res.id, store, (result) => {
          addAuth.value = result.addAuth;
          editAuth.value = result.editAuth;
          delAuth.value = result.delAuth;
        });
        getDataFn();
      })
    };

    const getDataFn = (flag = true) => {
      let args = {
        ...query.value,
        ...{
          prjId: project.value.id,
          pageNum: conf.value.pageNum,
          pageSize: conf.value.pageSize
        }
      }
      if (flag) {
        usersOptions.value = [];
        usersOptions.value.push({
          id: '-1',
          nickname: '全部'
        })
      }

      $api.sp.getShareInfo(args).then((res) => {
        tableData.value = res.list;
        conf.value.total = res.total;

        let uniq = [];
        tableData.value.forEach(v => {
          if (v.code)
            v.name = window.origin + '/ScreenPreview?shareId=' + v.id + '&code=' + v.code;
          else
            v.name = window.origin + '/ScreenPreview?shareId=' + v.id;

          if (flag && !_.contains(uniq, v.createBy)) {
            usersOptions.value.push({
              id: v.createBy,
              nickname: v.userName
            })
            uniq.push(v.createBy);
          }

        })
        record.value = JSON.stringify(tableData.value);
        loading.value = false;
      })
    };

    const onChange = (param) => {
      conf.value.pageSize = param.pageSize;
      conf.value.pageNum = param.currentPage;
      getDataFn();
    };

    const jumpSpace = (n) => {
      if (project.value.id != n.id) {
        jumpRouteSetup(emit, n).then(() => {
          router.push('/Main/SpaceDetail');
        })
      }
    };

    const editShot = (i, list) => {
      share.value = {
        title: "编辑分享",
        isEdit: true,
        isShow: true,
        info: { ...list[i] }
      }
      changeExpire(share.value.info.effect);
    };

    const shareDel = (i, list) => {
      $confirm("此操作将永久删除该分享记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          $api.sp.deleteShareById({ id: list[i].id }).then((res) => {
            $message.success('删除成功');
            getDataFn();
          });
        });
    };

    const shareHide = () => {
      share.value.isShow = false;
    };

    const saveShareCode = () => {
      $api.sp.updateShare(share.value.info).then(data => {
        $message.success('操作成功');
        getDataFn();
        shareHide();
      })
    };

    const filterByUser = (val) => {
      query.value.createBy = val;
      getDataFn(false);
    };

    const changeExpire = (val) => {
      let result = {};
      switch (val) {
        case 'hour1':
          result = {
            effect: 'hour1',
            value: 1,
          }
          break;
        case 'day1':
          result = {
            effect: 'day1',
            value: 1,
          }
          break;
        case 'day3':
          result = {
            effect: 'day3',
            value: 3,
          }
          break;
        case 'day7':
          result = {
            effect: 'day7',
            value: 7,
          }
          break;
        case 'day14':
          result = {
            effect: 'day14',
            value: 14,
          }
          break;
        case 'forever':
          result = {
            effect: 'forever',
            value: -1,
          }
          break;
        default:
          result = {
            effect: 'day14',
            value: 14,
          }
          break;
      }
      share.value.info = { ...share.value.info, ...result };
    };

    const switchClone = (val) => {
      if (!val)
        share.value.info.cloneCode = '';
    };

    onMounted(() => {
      getDetail();

    })
    return {
      loading,
      project,
      tableData,
      share,
      addAuth,
      editAuth,
      delAuth,
      conf,
      query,
      usersOptions,

      jumpSpace,
      onChange,
      editShot,
      shareDel,
      shareHide,
      filterByUser,
      changeExpire,
      saveShareCode,
      switchClone
    };
  },
}
</script>

<style lang="scss" scoped>

</style>