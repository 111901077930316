<template>
  <div class="query-area">
    <div class="left">
      <slot name="left"></slot>
    </div>
    <div class="right">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Query",
};
</script>

<style lang="scss" scoped>
.query-area {
  width: 100%;
  display: flex;
  flex-direction: row;

  .left {
    width: 1%;
    flex: 1;
    text-align: left;

    & > * {
      float: left;
    }
  }

  .right {
    width: auto;
    text-align: right;

    & > * {
      float: left;
    }
  }
}
</style>