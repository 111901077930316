<template>
  <div class="query-item">
    <b>{{ queryName }}：</b>
    <slot name="item"></slot>
  </div>
</template>

<script>
export default {
  name: "QueryItem",
  props: {
    queryName: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.query-item {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  float: left;
  margin-bottom: 10px;
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }

  b {
    font-weight: bold;
    display: flex;
    align-items: center;
    font-size: 14px;
    white-space: nowrap;
  }
}
</style>